import { render, staticRenderFns } from "./ProductSubmit.vue?vue&type=template&id=2a0dda16&scoped=true&"
import script from "./ProductSubmit.vue?vue&type=script&lang=js&"
export * from "./ProductSubmit.vue?vue&type=script&lang=js&"
import style0 from "./ProductSubmit.vue?vue&type=style&index=0&id=2a0dda16&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a0dda16",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnIcon: require('/home/php1/m-nuxt/components/YfnIcon.vue').default,BizRegister: require('/home/php1/m-nuxt/components/BizRegister.vue').default,YfnButton: require('/home/php1/m-nuxt/components/YfnButton.vue').default,YfnFooter: require('/home/php1/m-nuxt/components/YfnFooter.vue').default,YfnBag: require('/home/php1/m-nuxt/components/YfnBag.vue').default,CustomizeIframe: require('/home/php1/m-nuxt/components/CustomizeIframe.vue').default})
